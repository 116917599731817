<template>
  <div class="h-full d-theme-dark-bg">
    <div class="border border-solid d-theme-border-grey-light h-full">
      <div class="w-full flex justify-between" style="background-color: rgba(121,121,121,0.1);">
        <div class="flex items-center justify-start">
          <p class="text-xs font-medium p-2 text-dark text-bold">
            {{ data.loading ? 'Loading...' : 'Detail Asset' }}
          </p>
          <QueryEditor v-if="$can('view_query')" code="DETAIL_FIXED_ASSET"/>
        </div>
      </div>
      <div class="h-full">
        <div v-if="!selectedGroup" class="w-full h-full flex justify-center items-center">
          <p class="text-xs">Pilih transaksi disebelah kiri untuk melihat data.</p>
        </div>
        <div v-else class="tableSticky overflow-auto">
          <table class="w-full h-full border-collapse table-fixed">
            <thead class="font-extrabold border border-solid d-theme-border-grey-light">
              <tr class="d-theme-dark-bg border border-solid d-theme-border-grey-light">
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center w-16">Action</th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center w-32">Kode Asset</th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center w-84">Nama Asset</th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center w-12">Qty</th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center w-24">Satuan</th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center w-32">Tgl. Perolehan</th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center w-32">Tgl. Disusutkan</th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center w-24">Long Life</th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center w-32">Processed Count</th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center w-32">Nilai Total</th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center w-32">Nilai Bulanan</th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center w-32">Total Processed</th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center w-32">Balance</th>
              </tr>
            </thead>
            <tbody class="font-medium">
              <tr v-for="(item, index) in data.items" :key="index" class="cursor-pointer hover:font-medium">
                <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center">
                  <vs-dropdown vs-trigger-click class="dd-actions cursor-pointer xs" v-if="$canAny(['update_detail_setup_fixed_asset', 'delete_detail_setup_fixed_asset'])">
                    <vs-button class="py-2 px-3 small" type="filled" icon-pack="feather" icon="icon-more-horizontal"></vs-button>
                    <vs-dropdown-menu>
                      <vs-dropdown-item class="p-1" v-if="$can('update_detail_setup_fixed_asset')" @click="showModalEdit(item)"><span class="whitespace-no-wrap">Edit</span></vs-dropdown-item>
                      <vs-dropdown-item class="p-1" v-if="$can('delete_detail_setup_fixed_asset')" @click="confirmDelete(item.id)"><span class="whitespace-no-wrap text-danger">Hapus</span></vs-dropdown-item>
                    </vs-dropdown-menu>
                  </vs-dropdown>
                </td>
                <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left">{{ item.kode }}</td>
                <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left">{{ item.nama }}</td>
                <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left">{{ item.qty }}</td>
                <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left">{{ item.satuan }}</td>
                <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left">{{ item.tgl_perolehan }}</td>
                <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left">{{ item.tgl_mulai_disusutkan }}</td>
                <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center">{{ item.long_life }} bulan</td>
                <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center">{{ item.jml_proses }} x</td>
                <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right">{{ item.total | idr }}</td>
                <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right">{{ (item.total / item.long_life)  | idr }}</td>
                <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right">{{ item.total_proses | idr }}</td>
                <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right">{{ (item.total - item.total_proses) | idr }}</td>
              </tr>
              <!--empty state-->
              <tr v-if="data.items.length < 1">
                <td colspan="12" class="text-xs text-center p-2">Tidak ada data.</td>
              </tr>
              <!--spacer-->
              <tr v-if="data.items.length > 0" class="h-full">
                <td colspan="12"></td>
              </tr>
            </tbody>
            <tfoot>
              <tr class="d-theme-dark-bg border border-solid d-theme-border-grey-light">
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right"></th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right"></th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right"></th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right"></th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right"></th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right"></th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right"></th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right"></th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right"></th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right">{{ summary.grandNilaiTotal | idr }}</th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right">{{ summary.grandNilaiBulanan | idr }}</th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right">{{ summary.grandTotalProcessed | idr }}</th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right">{{ summary.grandTotalSisa | idr }}</th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>

    <!--modals-->
    <DetailAssetEdit :isActive.sync="modalDetailEdit.active" :idDetailAsset="modalDetailEdit.item.id" @success="getData"/>

  </div>
</template>

<script>
import SetupFixedAssetRepository from '@/repositories/accounting/setup-fixed-asset-repository'
import { v4 as uuid } from 'uuid'
import _ from 'lodash'

export default {
  name: 'DetailSection',
  components: {
    DetailAssetEdit: () => import('@/views/pages/accounting/setup/setup-fixed-asset/DetailAssetEdit'),
    QueryEditor: () => import('@/views/components/query-editor/QueryEditor')
  },
  computed: {
    dataHeaderSection () {
      return this.$store.state.accounting.setupFixedAsset.headerSection
    },
    dataGroupSection () {
      return this.$store.state.accounting.setupFixedAsset.groupSection
    },
    selectedGroup () {
      return this.dataGroupSection.selected
    },
    summary () {
      return {
        grandNilaiTotal: _.sumBy(this.data.items, item => parseFloat(item.total || '0')),
        grandNilaiBulanan: _.sumBy(this.data.items, item => parseFloat((item.total / item.long_life) || '0')),
        grandTotalProcessed: _.sumBy(this.data.items, item => parseFloat(item.total_proses || '0')),
        grandTotalSisa: _.sumBy(this.data.items, item => parseFloat(item.total || '0') - parseFloat(item.total_proses || '0'))
      }
    }
  },
  data () {
    return {
      modalDetailEdit: {
        active: false,
        item: {}
      },
      data: {
        loading: false,
        items: []
      }
    }
  },
  watch: {
    selectedGroup (newVal, oldVal) {
      const newId = newVal ? newVal.id : null
      const oldId = oldVal ? oldVal.id : null
      if (!_.isEmpty(newVal) && !_.isEqual(newId, oldId)) {
        this.refresh()
      }
    },
    data: {
      deep: true,
      handler (value) {
        this.$store.commit('accounting/setupFixedAsset/SET_DETAIL_SECTION', _.cloneDeep(value))
      }
    }
  },
  methods: {
    getData () {
      this.data.loading = true

      const params = {
        id_proyek: this.dataHeaderSection.filter.id_proyek,
        id_fixed_asset: this.selectedGroup.id
      }
      SetupFixedAssetRepository.getDetails(params)
        .then(response => {
          this.data.items = _.map(response.data.data, (item, index) => {
            item.uuid = uuid()
            return item
          })
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.data.loading = false
        })
    },

    delete (id) {
      SetupFixedAssetRepository.deleteDetail(id)
        .then(response => {
          this.getData()
          this.notifySuccess('Data berhasil terhapus')
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
    },

    showModalEdit (item) {
      this.modalDetailEdit.item = item
      this.modalDetailEdit.active = true
    },

    refresh () {
      this.data.items = []
      this.getData()
    },

    confirmDelete (id) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Konfirmasi',
        text: 'Yakin ingin hapus data ?',
        acceptText: 'Hapus',
        cancelText: 'Batal',
        accept: () => { this.delete(id) }
      })
    }

  }
}
</script>

<style>
.tableSticky {
  overflow: auto;
  height: calc(100vh - 284px);
}
.tableSticky thead {
  position: sticky;
  top: -1px;
  z-index: 1;
}
.tableSticky tfoot {
  position: sticky;
  bottom: -1px;
  z-index: 1;
}
</style>
